<template>
  <div class="switch">
    <!--将switch的项目 通过iframe链接过来-->
    <iframe
      name="hidden_frame"
      id="receivers_frame"
      frameborder="no"
      border="0"
      :src="url"></iframe>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import Bus from '@/assets/js/vueBus.js'
export default {
  data () {
    return {
      url: ''
    }
  },
  created () { // 获取switch的地址
    let protocol = window.location.protocol
    let default_lang = window.localStorage.getItem('lang') || navigator.language || navigator.userLanguage
    this.url = protocol + '//' + this.StateObj.common.baseUrl.ccSwitchUrl + '/?lang=' + default_lang

    Bus.$on('langSeltSwitch', data => { // 监听语言切换
      this.url = protocol + '//' + this.StateObj.common.baseUrl.ccSwitchUrl + '/?lang=' + data
    })
  },
  methods: {},
  computed: {
    ...mapState({
      StateObj: state => state
    })
  }
}
</script>
<style lang="less" scoped>
.switch {
  height: 100%;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
}
</style>
